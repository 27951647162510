<template>
  <div>
    <el-breadcrumb style="margin: 0 0 10px 10px" separator="/">
      <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">危废管理</div>
      <div class="xian"></div> -->
      <div class="list_box">
        <div class="tt1">账号列表</div>
        <div>
          <el-button
            size="small"
            type="add"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <span>真实姓名：</span>
        <el-input
          class="int_width"
          v-model="username"
          placeholder="请输入真实姓名"
          clearable
        ></el-input>
        <el-button size="small" type="cha" icon="el-icon-search" @click="chaxun"
          >查询</el-button
        >
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="userList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            width="60px"
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="real_name"
            label="真实姓名"
          ></el-table-column>
          <el-table-column align="center" label="类型">
            <template v-slot="scope">
              <div>{{ scope.row.duty === 1 ? '超级管理员' : '管理员' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="remark"
            label="备注"
          ></el-table-column>
          <el-table-column align="center" label="状态">
            <template v-slot="scope">
              <div>{{ scope.row.status === 1 ? '正常' : '冻结' }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="80px">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <!-- <el-button
                size="mini"
                type="danger"
                icon="iconfont icon-shanchu"
                @click="del(scope.row)"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <el-form ref="form" :rules="rules" :model="addform" label-width="160px">
          <el-form-item label="账号：" prop="username">
            <el-input class="int" v-model="addform.username" clearable></el-input>
            <span style="color: #ccc; margin-left: 5px"
              >注：字母数字下划线(2-10位)</span
            >
          </el-form-item>

          <el-form-item label="真实姓名：" prop="real_name">
            <el-input class="int" v-model="addform.real_name" clearable></el-input>
          </el-form-item>
          <el-form-item v-if="id" label="密码：">
            <el-input
              class="int"
              v-model="addform.password"
              show-password
              clearable
            ></el-input>
            <span style="color: #ccc; margin-left: 5px"
              >注：不填默认为原密码</span
            >
          </el-form-item>
          <el-form-item v-else label="密码：" prop="password">
            <el-input
              class="int"
              v-model="addform.password"
              show-password
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：">
            <!-- <el-input class="int" v-model="addform.duty"></el-input> -->
            <el-radio-group v-model="addform.duty">
              <el-radio :label="1">超级管理员</el-radio>
              <el-radio :label="2">管理员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态：">
            <el-radio-group v-model="addform.status">
              <el-radio :label="1">正常</el-radio>
              <el-radio :label="2">冻结</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              class="int"
              :autosize="{ minRows: 3 }"
              v-model="addform.remark"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn_box">
          <el-button
            v-if="id"
            type="cha"
            size="small"
            class="add_btn111"
            @click="editwaste"
            >确定</el-button
          >
          <el-button
            v-else
            type="cha"
            size="small"
            class="add_btn111"
            @click="addWaste"
            >确定</el-button
          >
          <el-button size="small" @click="handleClose">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      size: 10,
      username: '',
      userList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },
      dialogVisible: false,
      addform: {
        username: '',
        real_name: '',
        password: '',
        duty: 1,
        remark: '',
        status: 1,
      },
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        real_name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
      },

      // 编辑用
      id: '',
      text: '',
    }
  },
  created() {
    this.user()
  },
  methods: {
    handleSizeChange(val) {
      this.size = val
      this.user()
    },
    handleCurrentChange(val) {
      this.page = val
      this.user()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
      ;(this.addform = {
        username: '',
        real_name: '',
        password: '',
        duty: 1,
        remark: '',
        status: 1,
      }),
        (this.id = '')
    },

    // 列表
    async user() {
      const { data: res } = await this.$http.get('api/user/index', {
        params: {
          username: this.username,
          page: this.page,
          size: this.size,
        },
      })
      console.log(11, res)
      this.userList = res.data.list
      this.total = res.data.count
    },
    // 查询
    chaxun() {
      this.page = 1
      this.user()
    },
    // 点击添加按钮，弹出对话框
    add() {
      this.dialogVisible = true
      this.text = '添加'
    },

    // 添加
    addWaste() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/user/add',
          this.addform
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          username: '',
          real_name: '',
          password: '',
          duty: 1,
          remark: '',
          status: 1,
        }),
          (this.page = 1)
        this.user()
      })
    },

    edit(row) {
      ;(this.addform = {
        username: row.username,
        real_name: row.real_name,
        password: '',
        duty: row.duty,
        remark: row.remark,
        status: row.status,
        id: row.id,
      }),
        (this.id = row.id)
      this.text = '编辑'
      this.dialogVisible = true
    },
    // 编辑危废
    editwaste() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')

        const { data: res } = await this.$http.post(
          'api/user/edit',
          this.addform
        )
        if (res.code != 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          username: '',
          real_name: '',
          password: '',
          duty: 1,
          remark: '',
          status: 1,
        }),
          (this.id = '')
        this.user()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 40px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box {
    padding: 20px 1px;
  }
  .int_width {
    width: 240px;
    margin-right: 40px;
  }
  .int_width1 {
    width: 240px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 10px;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .int {
    width: 400px;
  }

  .add_btn {
    margin-left: 800px;
  }
  .danger_btn {
    margin-left: 20px;
  }
  .btn_box {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
  .add_btn111 {
    // width: 140px;
  }
}
</style>
